/* Vendor imports */
import React, { Component } from "react";
import PropTypes from 'prop-types'
/* App imports */
import TopButton from "../topButton/TopButton";
import {theme} from "../../theme.js"

import Header from './header'
// import Header from "../header/Header";
import Footer from "../footer/Footer";
// import Footer from './footer'
import '../../style/global.less'
import style from './layout.module.less'


export const darkTheme = {
  body: "#011627",
  text: "#ffffff",
  highlight: "#2ec4b6",
  dark: "#000000",
  secondaryText: "#63aeff",
  imageHighlight: "#0E6BA8",
  compImgHighlight: "#E6E6E6",
  jacketColor: "#0A2472",
  headerColor: "#0E6BA877",
};


const Layout = ({ children, title }) => (
  <>
    <Header theme={theme} />
    <div className={style.container}>
      {title ? (
        <div className={style.title}>
          <h1>{title}</h1>
        </div>
      ) : null}
      {children}
    </div>
    <Footer theme={theme} />
    <TopButton theme={theme} />
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
}

Layout.defaultProps = {
  title: '',
}

export default Layout
